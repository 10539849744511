import styles from './Footer.module.scss'
import TopWaves from '../../images/svg/topWaves.svg';
import Logo from '../../images/logo.png'
import { useState } from 'react';

function Footer() {
  const [showPopup, setShowPopup] = useState(false)
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.imgWrap}>
          <img src={TopWaves} alt="waves" className={styles.topWaves}/>
        </div>
        <div className={styles.wrap}>
          <img src={Logo} alt="waves" className={styles.logo}/>
          <h2 onClick={() => setShowPopup(true)}>Legal</h2>
        </div>
      </div>
      { showPopup 
        ?<div className={styles.popup}>
          <div className={styles.popupWrap}>
            <div className={styles.closeButton} onClick={() => setShowPopup(false)}>Close</div>
            <div className={styles.textWrap}>
              <h3>Contato</h3>
              <h3>Korumei LDA</h3>
              <h3>Gerente: Beatriz del Carmen Pizarro</h3>
              <p>Sede do Korumei</p>
              <p>Avda Valerio Pinto de Sa nº20 EE16</p>
              <p>Braga 4700-098</p>
              <p>Portugal</p>
              <p>info@korumei.com</p>

              <h3>Política de privacidad</h3>
              <h4>§ 1 Información sobre la recogida de datos personales</h4>
              <p>A continuación le informamos sobre la recogida de datos personales al utilizar nuestro sitio web. Los datos personales son todos los datos que pueden relacionarse con usted personalmente, por ejemplo, nombre, dirección, direcciones de correo electrónico, comportamiento del usuario.</p>
              <p>El responsable en virtud Reglamento General de Protección de Datos de la UE es Korumei LDA, info@korumei.com Ver datos en contacto.</p>
              <p>Cuando se ponga en contacto con nosotros por correo electrónico o a través de un formulario de contacto, los datos que nos proporcione (su dirección de correo electrónico, nombre y número de teléfono, si procede) serán almacenados por nosotros para responder a sus preguntas. Eliminamos los datos acumulados en este contexto una vez que el almacenamiento ya no es necesario o restringimos el procesamiento si existen obligaciones legales de retención.</p>
              <p>Si utilizamos proveedores de servicios encargados para funciones individuales de nuestra oferta o deseamos utilizar sus datos con fines publicitarios, le informaremos detalladamente sobre los procesos respectivos a continuación. Al hacerlo, también indicaremos los criterios especificados para el periodo de almacenamiento.</p>

              <h4>§ 2 Sus derechos</h4>
              <p>Usted tiene los siguientes derechos en relación con sus datos personales: </p>
              <ul>
                <li>derecho de acceso a sus datos personales</li>
                <li>derecho a la información</li>
                <li>derecho de rectificación o supresión</li>
                <li>derecho a la limitación del tratamiento</li>
                <li>derecho a oponerse al tratamiento,</li>
                <li>derecho a la portabilidad de los datos.</li>
              </ul>
              <p>También tiene derecho a presentar una reclamación ante una autoridad de control de protección de datos sobre el tratamiento de sus datos personales.</p>

              <h4>§ 3 Recogida de datos personales al visitar nuestro sitio web</h4>
              <p>Cuando utiliza nuestro sitio web únicamente con fines informativos, es decir, si no se registra ni nos transmite información de otro modo, sólo recogemos los datos personales que su navegador transmite a nuestro servidor. Si desea ver nuestro sitio web, recopilamos los siguientes datos, que son técnicamente necesarios para mostrarle nuestro sitio web y para garantizar su estabilidad y seguridad:</p>
              <ul>
                <li>Dirección IP</li>
                <li>dirección IP</li>
                <li>fecha y hora de la solicitud</li>
                <li>diferencia horaria con respecto a la hora del meridiano de Greenwich (GMT)</li>
                <li>Contenido de la solicitud (página específica)</li>
                <li>estado de acceso/código de estado HTTP</li>
                <li>cantidad de datos transferidos</li>
                <li>Sitio web del que procede la solicitud</li>
                <li>navegador</li>
                <li>Sistema operativo y su interfaz</li>
                <li>Idioma y versión del software del navegador.</li>
              </ul>
              <p>Además de los datos mencionados anteriormente, las cookies se almacenan en su ordenador cuando utiliza nuestro sitio web. Las cookies son pequeños archivos de texto que se almacenan en su disco duro en relación con el navegador que está utilizando y que proporcionan a la parte que establece la cookie (en este caso, nosotros) determinada información. Las cookies no pueden ejecutar programas ni transferir virus a su ordenador. Se utilizan para hacer que la oferta de Internet en su conjunto sea más fácil de usar y eficaz.</p>

              <h4>§ 4 Uso de cookies:</h4>
              <p>(a) Este sitio web utiliza los siguientes tipos de cookies, cuyo alcance y funcionamiento se explican a continuación:</p>
              <ul>
                <li>Cookies transitorias (ver b)</li>
                <li>Cookies persistentes (véase c).</li>
              </ul>
              <p>b) Las cookies transitorias se eliminan automáticamente al cerrar el navegador. Entre ellas se incluyen, en particular, las cookies de sesión. Éstas guardan el llamado identificador de sesión, con el que se pueden asignar varias solicitudes de su navegador a la sesión conjunta. Esto permite reconocer su ordenador cuando vuelva a visitar nuestro sitio web. Las cookies de sesión se borran al cerrar la sesión o el navegador. </p>
              <p>c) Las cookies persistentes se eliminan automáticamente tras un periodo de tiempo determinado, que puede variar en función de la cookie. Puede eliminar las cookies en la configuración de seguridad de su navegador en cualquier momento.</p>
              <p>d) Puede configurar los ajustes de su navegador según sus preferencias y, por ejemplo, negarse a aceptar cookies de terceros o todas las cookies. Tenga en cuenta que es posible que no pueda utilizar todas las funciones de este sitio web.</p>

              <h4>§ 5 Uso de Google Analytics</h4>
              <p>a) Este sitio web utiliza Google Analytics, un servicio analítico de web prestado por Google, Inc ("Google"). Google Analytics utiliza "cookies", que son archivos de texto ubicados en su ordenador, para ayudar al website a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso de este sitio web normalmente se transmite a un servidor de Google en los EE.UU. y se almacena allí. Sin embargo, en caso de que se active la anonimización de IP en este sitio web, su dirección IP será truncada previamente por Google dentro de los estados miembros de la Unión Europea o en otros estados contratantes del Acuerdo sobre el Espacio Económico Europeo. Sólo en casos excepcionales se transmitirá la dirección IP completa a un servidor de Google en EE.UU. y se acortará allí. En nombre del operador de este sitio web, Google utilizará esta información con el fin de evaluar su uso del sitio web, recopilando informes de la actividad del sitio web y prestando otros servicios relacionados con la actividad del sitio web y el uso de Internet al operador del sitio web.</p>
              <p>b) La dirección IP transmitida por su navegador como parte de Google Analytics no se fusionará con otros datos de Google.</p>
              <p>c) "Usted puede rechazar el uso de cookies, mediante la selección de la configuración apropiada de su navegador, sin embargo, tenga en cuenta que si lo hace es posible que no pueda utilizar todas las funciones de este sitio web. También puede impedir la recopilación de los datos generados por la cookie y relacionados con su uso del sitio web (incluida su dirección IP) por parte de Google y el procesamiento de estos datos por parte de Google descargando e instalando el complemento del navegador disponible en el siguiente enlace: http://tools.google.com/dlpage/gaoptout?hl=de.</p>
              <p>d) Este sitio web utiliza Google Analytics con la extensión "_anonymizeIp()". Esto significa que las direcciones IP se procesan de forma abreviada, excluyendo así la posibilidad de referencias personales. En la medida en que los datos recopilados sobre usted sean identificables personalmente, esto se excluye de inmediato y, por lo tanto, los datos personales se eliminan de inmediato.</p>
              <p>e) "Utilizamos Google Analytics para analizar y mejorar periódicamente el uso de nuestro sitio web. Las estadísticas obtenidas nos permiten mejorar nuestra oferta y hacerla más interesante para usted como usuario. Para los casos excepcionales en los que se transfieren datos personales a EE.UU., Google se ha sometido al Escudo de Privacidad UE-EE.UU., https://www.privacyshield.gov/EU-US-Framework. </p>
              <p>f) Información del proveedor externo: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublín 4, Irlanda, Fax: +353 (1) 436 1001. Condiciones de uso: http://www.google.com/analytics/terms/de.html, resumen de protección de datos: http://www.google.com/intl/de/analytics/ learn/privacy.html, así como la declaración de protección de datos: http://www.google.de/intl/de/policies/privacy.</p>
              
              <h4>§ 6 Uso de plug-ins de redes sociales</h4>
              <p>a) `Actualmente utilizamos los siguientes plug-ins de redes sociales: Facebook, Glassdoor, Twitter, Xing, Vimeo, LinkedIn, etc. Utilizamos la denominada solución de dos clics, lo que significa que cuando usted visita nuestro sitio, no se transmite inicialmente ningún dato personal a los proveedores de los plug-ins. Puede identificar al proveedor del plug-in marcando la casilla con su letra inicial o logotipo. Le damos la oportunidad de comunicarse directamente con el proveedor del plug-in a través del botón. Sólo si hace clic en la casilla marcada y la activa, el proveedor del plug-in recibe la información de que usted ha accedido al sitio web correspondiente de nuestra oferta en línea. Además, se transmiten los datos mencionados en el § 3 de esta declaración. En el caso de Facebook y Xing, de acuerdo con los respectivos proveedores, la dirección IP se anonimiza inmediatamente después de la recopilación. Al activar el plug-in, sus datos personales se transmiten al respectivo proveedor del plug-in y allí (en el caso de los plug-ins estadounidenses) se hacen anónimos. Dado que el proveedor del complemento recopila los datos en particular a través de cookies, le recomendamos que elimine todas las cookies a través de la configuración de seguridad de su navegador antes de hacer clic en la casilla atenuada.</p>
              <p>b) No tenemos ninguna influencia sobre los datos recogidos y las operaciones de tratamiento de datos, ni tenemos conocimiento del alcance total de la recogida de datos, los fines del tratamiento, los períodos de almacenamiento. Tampoco tenemos información sobre la supresión de los datos recogidos por parte del proveedor del complemento.</p>
              <p>c) El proveedor del plug-in almacena los datos recogidos sobre usted como perfiles de uso y los utiliza con fines publicitarios, de investigación de mercado y/o de diseño orientado a la demanda de su sitio web. Dicha evaluación se lleva a cabo en particular (también para usuarios que no han iniciado sesión) para la visualización de publicidad basada en las necesidades y para informar a otros usuarios de la red social sobre sus actividades en nuestro sitio web. Usted tiene derecho a oponerse a la creación de estos perfiles de usuario, para lo cual deberá ponerse en contacto con el proveedor del plug-in correspondiente para ejercer este derecho. A través de los plug-ins, le ofrecemos la oportunidad de interactuar con las redes sociales y otros usuarios para que podamos mejorar nuestra oferta y hacerla más interesante para usted como usuario.</p>
              <p>d) La transferencia de datos tiene lugar independientemente de si usted tiene una cuenta con el proveedor del plug-in y ha iniciado sesión en ella. Si ha iniciado sesión en el proveedor del plug-in, sus datos recopilados por nosotros se asignarán directamente a su cuenta con el proveedor del plug-in. Si hace clic en el botón activado y, por ejemplo, enlaza con la página, el proveedor del plug-in también guarda esta información en su cuenta de usuario y la comparte públicamente con sus contactos. Le recomendamos que cierre la sesión regularmente después de utilizar una red social, pero especialmente antes de activar el botón, ya que esto le ayudará a evitar una asignación a su perfil con el proveedor del plug-in.</p>
              <p>e) Puede obtener más información sobre la finalidad y el alcance de la recopilación de datos y su tratamiento por parte del proveedor del plug-in en las declaraciones de protección de datos de estos proveedores que se comunican a continuación. Allí también recibirá más información sobre sus derechos a este respecto y las opciones de configuración para proteger su privacidad. </p>
              <p>f) Direcciones de los respectivos proveedores de plug-ins y URL con sus declaraciones de protección de datos:</p>
              <ul>
                <li>Facebook Inc, 1601 S California Ave, Palo Alto, California 94304, USA;</li>
                <li>http://www.facebook.com/policy.php; más información sobre la recogida de datos: http://www.facebook.com /help/186325668085084, http://www.facebook.com/about/privacy/your-info-on-other#applications así como http://www.facebook.com/about/privacy/your-info#everyoneinfo. Facebook se ha sometido al Escudo de Privacidad UE-EE.UU., https://www.privacyshield.gov/EU-US-Framework.`.</li>
                <li>`Glassdoor Inc, 100 Shoreline Highway, Mill Valley, California, EE.UU.; https://www.glassdoor.de/about/privacy.htm`.</li>
                <li>Twitter, Inc, 1355 Market St, Suite 900, San Francisco, California 94103, EE.UU.; https://twitter.com/privacy. Twitter se ha sometido al Escudo de Privacidad UE-EE.UU., https://www.privacyshield.gov/EU-US-Framework.`.</li>
                <li>`Xing AG, Gänsemarkt 43, 20354 Hamburgo, DE; http://www.xing.com/privacy.`.</li>
                <li>`Vimeo Inc 555 West 18th Street New York, New York 10011, USA; https://vimeo.com/privacy`.</li>
                <li>`LinkedIn Corporation, 2029 Stierlin Court, Mountain View, California 94043, USA; http://www.linkedin.com/legal/privacy-policy. LinkedIn se ha sometido al Escudo de Privacidad UE-EE.UU., https://www.privacyshield.gov/EU-US-Framework.`.</li>
              </ul>
             
              <h4>§ 7 Integración de vídeos de YouTube</h4>
              <p>a) Hemos integrado vídeos de YouTube en nuestra oferta en línea, que se almacenan en http://www.YouTube.com y pueden reproducirse directamente desde nuestro sitio web. Todos ellos están integrados en "modo de protección de datos ampliado", es decir, no se transmiten datos sobre usted como usuario a YouTube si no reproduce los vídeos. Sólo cuando reproduzca los vídeos se transmitirán los datos mencionados en el apartado 2. No tenemos ninguna influencia sobre esta transmisión de datos.</p>
              <p>b) `Al visitar el sitio web, YouTube recibe la información de que usted ha accedido a la correspondiente subpágina de nuestro sitio web. Además, se transmiten los datos mencionados en el apartado 3 de esta declaración. Esto ocurre independientemente de si YouTube proporciona una cuenta de usuario a través de la cual usted ha iniciado sesión o si no existe ninguna cuenta de usuario. Si ha iniciado sesión en Google, sus datos se asignarán directamente a su cuenta. Si no desea que sus datos se asocien a su perfil de YouTube, debe cerrar la sesión antes de activar el botón. YouTube almacena sus datos como perfiles de uso y los utiliza con fines publicitarios, de investigación de mercado y/o para diseñar su sitio web de acuerdo con los requisitos. Dicha evaluación se lleva a cabo en particular (incluso para los usuarios que no han iniciado sesión) para proporcionar publicidad basada en las necesidades y para informar a otros usuarios de la red social sobre sus actividades en nuestro sitio web. Usted tiene derecho a oponerse a la creación de estos perfiles de usuario, y debe ponerse en contacto con YouTube para ejercer este derecho.</p>
              <p>c) `Puede encontrar más información sobre la finalidad y el alcance de la recogida de datos y su tratamiento por parte de YouTube en la política de privacidad. Allí también encontrarás más información sobre tus derechos y opciones de configuración para proteger tu privacidad: https://www.google.de/intl/de/policies/privacy. Google también procesa sus datos personales en EE.UU. y se ha sometido al Escudo de Privacidad UE-EE.UU., https://www.privacyshield.gov/EU-US-Framework.`.</p>
            </div>
          </div>
        </div>
        : null
      }
      
    </div>
  );
}
export default Footer;
