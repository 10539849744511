import styles from './Landing.module.scss';
import Logo from '../../images/logoBlue.png';
import Squares from '../../images/svg/squares.svg';
import TitleDots from '../../images/svg/TitleDots.svg';
import BgDoubleSquare from '../../images/svg/BgDoubleSquare.svg';
import Home from '../../images/Home.jpg';

function Landing() {
  return (
    <div className={styles.main} id="landing">
      <img src={Squares} alt="squares" className={styles.squares} />
    
      <div className={styles.box}>
        <div className={styles.innerBox}>
          <div style={{ backgroundImage: 'url("./images/Home.jpg")'}} />
        </div>
        <div className={styles.firstBox} />
        <div className={styles.outerBox} />
      </div>
      
      <div className={styles.wrap}>
      <img src={Logo} alt="Korumei" className={styles.logo}/>
        <div className={styles.text}>
          <div className={styles.hWrap}>
            <h1>KORUMEI</h1>
            <img src={TitleDots} alt="tittledots" className={styles.dots} />
          </div>
          <h4>Formación permanente de innovación humana.</h4>
          <p>Somos una empresa que brinda experiencias formativas basadas en las neurociencias para profesionales que eligen su propio camino.</p>
        </div>
      </div>

    </div>
  );
}
export default Landing;

