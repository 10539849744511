import { useEffect } from 'react';
import styles from './CourseScreen.module.scss';
import { useParams } from 'react-router-dom'
import Logo from '../../images/logoBlue.png'
import TitleDots from '../../images/svg/TitleDots.svg';
import Squares from '../../images/svg/squaresCourses.svg';
import { useNavigate } from 'react-router-dom';

function CourseScreen() {
  let { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const coursesList = [
    { title: 'Un workplace sin conflictos, creando un entorno productivo y armonioso.', text: `
    Cuando cometemos errores, debemos valorar las circunstancias en su justa medida, tratar de comprender la situación y sus detonantes, y convertir este aprendizaje en una alerta para no volver a repetir la equivocación. A esto lo llamamos Experiencias de Vida.

    Los conflictos en el lugar de trabajo son inevitables en cualquier organización. Surgen naturalmente de las interacciones humanas, las diferencias personales y los objetivos contrapuestos. Sin embargo, si no se manejan adecuadamente, estos conflictos pueden resultar muy perjudiciales, creando ambientes laborales tensos, improductivos y tóxicos. Al abordar los conflictos de manera constructiva fortalecemos las relaciones, la colaboración y los resultados organizacionales.

    Con esta Experiencia, Korumei busca dotar a los miembros de una empresa de un conjunto de estrategias y habilidades para prevenir, gestionar y resolver conflictos de manera efectiva, fomentando lugares de trabajo más armoniosos y productivos.

    Durante su transcurso analizaremos en profundidad diversos tipos de conflictos, a partir de las experiencias individuales y grupales que pueden ocurrir en las organizaciones, desde desacuerdos menores hasta disputas mayores que involucran valores e intereses arraigados. Exploraremos sus múltiples causas, que pueden abarcar diferencias de personalidad, pugnas por recursos, problemas de comunicación, roles mal definidos, objetivos contrapuestos o cambios organizacionales, entre otros. Asimismo, examinaremos el ciclo típico de escalada de un conflicto cuando no se interviene a tiempo y cómo esto desemboca en resultados nocivos como la disminución de la cooperación, el afecto, la confianza y el desempeño.

    Dedicaremos toda la Experiencia a desarrollar la mentalidad y las actitudes necesarias de los líderes para abordar los conflictos de manera constructiva, en lugar de evitarlos o agravarlos. Analizaremos cómo manejar las propias emociones, mantener una mente abierta, enfocarse en necesidades comunes y comunicarse con asertividad y empatía. También discutiremos estrategias para identificar el problema real detrás del conflicto e intervenir tempranamente utilizando un proceso estructurado de resolución de disputas.

    Nuestra Playlife Experience permitirá comprender el valor del diálogo, la escucha activa y la negociación ganar-ganar para alcanzar acuerdos satisfactorios para todas las partes. Y finalmente, veremos cómo sanar las relaciones dañadas y restaurar un clima de entendimiento y colaboración después de un conflicto.
    `, img: 'url("../images/CursoG1.png")' },
    { title: 'Cómo dar buenas noticias a equipos difíciles.', text: `
    ¿Recuerdan cuándo fue la última vez que recibieron una buena noticia? De esas que alegran el día o el mes. Esas noticias que hacen que se nos escape una sonrisa o que sintamos unas ganas irrefrenables de saltar. Espero que no tengas que hacer mucho esfuerzo para recordar cuándo sucedió algo así. Y otra pregunta: ¿Recuerdas cuándo fuiste tú el portador de una buena noticia? Queremos ofrecerte la experiencia de aprender a dar buenas nuevas cuando los tiempos no son sencillos ni fáciles.

    Estimados, las buenas noticias nunca llegan solas, ni se viven solas. La experiencia nos indica que los equipos de trabajo son fundamentales en cualquier organización. Sin embargo, no todos los equipos funcionan de forma armoniosa y eficiente. Algunos se vuelven disfuncionales debido a problemas de comunicación, objetivos contrapuestos, falta de motivación o dinámicas interpersonales negativas. Saber gestionar equipos difíciles es un desafío clave para cualquier líder.

    Un aspecto crucial es cómo comunicarles buenas nuevas, ya que, si no se hace bien, se pueden exacerbar conflictos latentes. Por el contrario, si se anuncian adecuadamente, las buenas noticias pueden mejorar significativamente la moral y el desempeño. En Korumei hemos elaborado una Playlife Experience que une un marco conceptual con el desarrollo de habilidades prácticas para comunicar buenas nuevas a equipos problemáticos de manera efectiva.

    Se explorarán temas como las dinámicas emocionales en equipos disfuncionales, y a través del juego se vivenciarán experiencias donde se enmarque y presente la información, se adapten los mensajes a diferentes integrantes, se manejen técnicas para enfrentar reacciones negativas y estrategias para reforzar los aspectos positivos.

    Además, conoceremos los principios y las herramientas de la neurociencia aplicada a la gestión de equipos, la neurociencia social, que sugiere que los grupos desarrollan una inteligencia emocional colectiva, producto de la interacción de las mentes individuales. Entender esta dinámica permitirá adaptar los mensajes positivos de forma personalizada. También se explorará cómo activar redes neuronales asociadas a emociones positivas y sentido de logro compartido. 
    El objetivo es empoderar a los participantes para aprovechar estas oportunidades, ofrecer experiencias de vida altamente valiosas y vinculantes, y marcar puntos de inflexión en el ámbito laboral y organizacional.
    `, img: 'url("../images/CursoG2.png")' },
    { title: 'Being Tough, recuperarse de los fallos.', text: `
    ¿Quién no ha tenido alguna vez, en el ámbito personal o laboral, algún fallo? Nadie desconoce que, en el mundo actual, estamos constantemente sometidos a desafíos y situaciones de incertidumbre que terminan cobrando su cuota, ya sea en forma de estrés, frustración o desmotivación. Estas emociones negativas pueden afectar el rendimiento, la salud y el bienestar de las personas, así como su capacidad para adaptarse y aprender de los errores. Por eso, es fundamental "ser duros", es decir, desarrollar la resiliencia, la habilidad de sobreponerse a las adversidades y salir fortalecido de ellas.

    La resiliencia no es un rasgo innato, sino que se puede entrenar y mejorar a través de diversas estrategias cognitivas, emocionales y conductuales. La neurociencia ha aportado evidencia sobre los mecanismos cerebrales que intervienen en la resiliencia y cómo se pueden modificar mediante la neuroplasticidad. Así, se ha demostrado que la resiliencia se asocia con una mayor actividad de la corteza prefrontal, que regula las funciones ejecutivas, el autocontrol y la toma de decisiones; y una menor actividad de la amígdala, que procesa las emociones negativas como el miedo y la ansiedad.

    Comenzaremos explorando cómo nuestras creencias condicionan nuestra actitud frente a las situaciones difíciles. Analizaremos los enfoques fijos versus de crecimiento ante el fracaso, para luego desarrollar habilidades como mantener una perspectiva amplia, reencuadrar los problemas como oportunidades y enfocarse en el aprendizaje. También abordaremos los peligros de la mentalidad victimista y las excusas limitantes, reemplazándolas por un sentido de responsabilidad y control personal.

    Dedicaremos una sección a prácticas para gestionar las emociones negativas que surgen ante el fracaso, como la frustración, la vergüenza y el miedo. Aprenderemos técnicas para manejar estos sentimientos, recobrar la motivación y evitar quedar atrapados en pensamientos derrotistas. Asimismo, exploraremos estrategias para autoevaluarnos objetivamente, aceptar la realidad del error cometido, aprender de ello y seguir adelante con decisión.

    Jugar con casos reales y recrear experiencias formativas nos permitirá encontrar herramientas para afrontar constructivamente los errores, tanto propios como de colaboradores y equipos. Exploraremos temas como el manejo del estrés, el pensamiento positivo y cómo fomentar una cultura de responsabilidad.

    Nuestra Playlife Experience ha sido pensada para brindar a los profesionales herramientas teóricas y prácticas para desarrollar la resiliencia y mejorar su capacidad de afrontar los desafíos y recuperarse de los fallos.
    `, img: 'url("../images/CursoG3.png")' },
    { title: 'MOMENTOS DECISIVOS: LA NEUROCIENCIA EN LA TOMA DE DECISIONES.', text: `
    Toda responsabilidad está encaminada, nos guste o no, hacia la toma de decisiones. Como todo lo que hacemos, las experiencias de vida nos enseñan que a veces no somos lo suficientemente abiertos a las innumerables variantes que implica toda decisión.

    La neurociencia nos entrega poderosos insights sobre cómo funciona el cerebro al decidir, y cómo optimizar este proceso incluso en las circunstancias más desafiantes. Por ello, esta Playlife Experience explorará los fundamentos neurocientíficos de la toma de decisiones, para que los participantes puedan entender y manejar mejor sus procesos mentales en momentos clave.

    Exploraremos el fenómeno de la disonancia cognitiva y su influencia en el arrepentimiento y cambio de decisiones, pues tendemos a utilizar atajos mentales o heurísticas que sesgan nuestras decisiones de manera predecible. Cada uno tiene su propio esquema de la realidad y sus propios sesgos condicionantes.

    Dedicaremos una sección a comprender estos sesgos, como el de confirmación, exceso de confianza, aversión a la pérdida, entre muchos otros. Desarrollaremos estrategias basadas en la metacognición para mitigar el impacto de estos atajos mentales en la toma de decisiones a través de una combinación de presentaciones teóricas, análisis de casos reales, discusiones en grupo y ejercicios prácticos.

    Experimentaremos cómo mitigar la incertidumbre, calmar la ansiedad y conectar con la intuición, y profundizaremos en técnicas para deliberar de manera focalizada, como los marcos de decisión de Kleindorfer.

    No hay duda de que la optimización en la toma de decisiones en momentos cruciales no solo mejora la efectividad individual, sino que también impacta en el éxito de la organización. Y es aquí donde Korumei se propone ofrecer su experiencia.
    `, img: 'url("../images/CursoG4.png")' },
    { title: 'LA COMUNICACIÓN SÓLIDA. EL PODER DEL SILENCIO EN EL MUNDO DIGITAL.', text: `
    Un viejo refrán árabe dice: “Escucha y serás sabio, porque el comienzo de la sabiduría es el silencio”. Cuando muchos han enseñado que la comunicación bien puede convertirte en líder, creamos una Playlife Experience que te permitirá comprender que un gran talento no consiste en saber qué se ha de decir, sino en saber lo que se ha de callar. Las palabras no siempre son la mejor respuesta, y esto es algo que deberíamos aprender y aplicar en nuestras vidas.

    En la era de la sobrecarga de información y la hiperconectividad, el silencio se ha convertido en un recurso escaso pero invaluable. Practicar el silencio intencionado tanto en la comunicación personal como digital se ha vuelto una competencia clave para líderes y profesionales. Korumei ofrece la experiencia de hacer de la comunicación sólida, el poder del silencio en este mundo digital. A través del juego como disparador, exploraremos el uso estratégico del silencio que fortalece la comunicación en un mundo digital ruidoso. Proporcionaremos una base científica basada en la neurociencia para comprender por qué el silencio es tan efectivo en ciertos contextos.

    Numerosos estudios han demostrado los beneficios del silencio y la contemplación para el bienestar, la creatividad y el equilibrio mental. Cuando existe un bombardeo constante de estímulos e información, el cerebro entra en un estado de saturación que dificulta la reflexión, el enfoque y la comunicación efectiva.

    Asimismo, se profundizará en el concepto de "silencio deliberado" de Ephratt. El silencio intencional proyecta seguridad y experiencia, siendo más persuasivo que el hablar por hablar. También comunica cualidades clave como la empatía, dando espacio a que otros se expresen sin interrumpirlos.

    Se presentarán principios retóricos clave como "kairós", el timing oportuno para hablar según contexto y audiencia. También "aposiopesis", la figura de interrumpir de forma abrupta un discurso para generar suspenso e interés. Usar el silencio con propósito, aplicando estos principios, da peso y magnetismo a las palabras.

    El silencio ayuda a procesar ideas y sentimientos, conectar con la intuición y cultivar la escucha profunda hacia los demás. Esto se traduce en una comunicación más consciente, creativa y empática.

    Los participantes explorarán experiencias del poder transformador del silencio en cuatro dimensiones: interior, comunicacional, creativa y colectiva; que integran el silencio como un aliado para comunicarse con mayor consciencia, profundidad y creatividad en entornos digitales.

    También estudiaremos el valor del silencio para escuchar realmente y comprender mejor a los demás, fortaleciendo vínculos y empatía. Aprenderemos sobre la importancia de los espacios de silencio compartido para la cohesión de los equipos y cultivar ambientes de confianza abierta.

    A través de esta Playlife Experience nuestros participantes aprenderán a utilizar el silencio como herramienta para transmitir mensajes claros, fomentar la reflexión y mejorar la calidad de las interacciones digitales.
    `, img: 'url("../images/CursoG5.png")' },
    { title: 'PATRONES MENTALES DEL PENSAMIENTO OPTIMISTA', text: `
    El optimismo es una cualidad esencial del liderazgo efectivo y el bienestar personal. Sin embargo, en algunas circunstancias, cuánto cuesta mantener una actitud y una perspectiva positiva.
    Hay ámbitos donde las presiones y contratiempos de la vida cotidiana e incluso profesional no lo hacen sencillo. No es suficiente con repetirse “sé positivo”. Evitar sucumbir ante dificultades y problemas, grandes o pequeños, es también una actitud que puede aprenderse.
    Les ofrecemos ser parte de nuestra Playlife Experience “Patrones mentales del pensamiento optimista y su efecto en el liderazgo empresarial”. 
    El contenido de esta experiencia está asentado sobre una base teórica sólida respaldada por la neurociencia, que nos guiará a explorar en profundidad los patrones de pensamiento que sustentan el optimismo, para que los participantes puedan cultivar intencionalmente este enfoque mental tan valioso.

    El pensamiento optimista es una forma de interpretar la realidad que se basa en la confianza en las propias capacidades, en las oportunidades de mejora y en la esperanza de un futuro favorable. No es una negación de los problemas o dificultades, sino una actitud positiva que busca soluciones y aprendizajes. El pensamiento optimista tiene múltiples beneficios para la salud física y mental, como reducir el estrés, aumentar la autoestima, mejorar el rendimiento y la creatividad, y favorecer las relaciones interpersonales.

    En “Patrones mentales del pensamiento optimista” comenzaremos analizando la base biológica del optimismo a nivel cerebral, y cómo promover patrones neuronales que refuercen expectativas positivas, resiliencia y motivación hacia las metas. Luego, estudiaremos el poder de las creencias profundas para moldear nuestra realidad a través del fenómeno de la profecía autocumplida. Aprenderemos a reemplazar narrativas limitantes por historias empoderadoras sobre nosotros mismos y nuestras circunstancias.

    Asimismo, se presentarán técnicas de visualización creativa, que pueden programar la mente para la realización exitosa de metas. Imaginar los resultados deseados actúa como una profecía auto-cumplida otorgando energía y enfocando la ejecución.

    También se explorarán herramientas para identificar y transformar creencias limitantes, que obstaculizan nuestro progreso. Mediante ejercicios y actividades participativas se generarán capacidades para eliminar patrones mentales contraproducentes.

    Exploraremos también cómo el lenguaje configura nuestra mentalidad, y técnicas para utilizar un vocabulario orientado a soluciones, que enfatice opciones y próximos pasos en lugar de problemas. Asimismo, analizaremos la importancia de enmarcar situaciones de forma constructiva, para extraer aprendizajes incluso de las experiencias más negativas.

    Esta experiencia de vida brinda a nuestros participantes la oportunidad de ser parte de una comunidad de aprendizaje que, a partir del juego, otorgará herramientas teóricas y prácticas para desarrollar y fortalecer patrones mentales de pensamiento optimista. Una experiencia pensada para el desarrollo de mentes ágiles capaces de encontrar soluciones y oportunidades en ámbitos laborales difíciles.
    `, img: 'url("../images/CursoG6.png")' },
    { title: 'DESARROLLAR LA MEMORIA DE FUTURO: TOMAR EL CONTROL DE TU AGENDA', text: `
    La capacidad de visualizar el futuro es una habilidad crucial para trazar el rumbo que deseamos en nuestra vida personal y profesional. La memoria de futuro es un concepto relativamente nuevo que se refiere a la habilidad de imaginar eventos positivos en el futuro como una forma de motivación para alcanzar metas.

    La memoria de futuro es una forma de viaje mental en el tiempo, que consiste en proyectarse mentalmente hacia el futuro para anticipar y prepararse para posibles eventos.

    Investigaciones recientes en psicología cognitiva y neurociencia indican que visualizar el futuro deseado activa las mismas regiones cerebrales que cuando recordamos el pasado, sugiriendo que el cerebro "simula" eventos futuros usando información de experiencias previas. Nuestro cerebro es un "órgano prospectivo", con un extraordinario poder para imaginar el mañana e influir sobre él a través de nuestras expectativas y acciones de hoy.

    Esta capacidad de "pre-experimentar" el futuro mediante la memoria episódica parece ser única de los humanos y está asociada con una mayor percepción de bienestar y propósito. Las personas que regularmente piensan y visualizan sus metas futuras están mejor equipadas para controlar su atención, motivación y comportamiento en el presente en pos de esos objetivos.

    Esta experiencia de vida explorará cómo optimizar nuestra "memoria del futuro" para tomar control de nuestra agenda y alcanzar nuestras metas.

    Ser capaces de adelantarnos hacia el futuro es lo que determina en muchos casos el éxito o el fracaso de nuestro actuar. Y esta no es una simple afirmación porque posee un sustento científico. En estos tiempos es posible explorar y optimizar la memoria prospectiva aplicando los nuevos descubrimientos de las neurociencias al comprender que cuando imaginas algo futuro, lo que haces es poner a trabajar las mismas áreas cerebrales que usarías si la experiencia fuera real. Imaginar lo que quieras, un producto, un servicio, un programa, pone en ejecución patrones neuronales similares a la práctica física, y por ende, refuerzas y desarrollas todas tus habilidades.

    Este programa explorará cómo optimizar nuestra "memoria del futuro" para tomar control de nuestra agenda y alcanzar nuestras metas, a través de estrategias cognitivas innovadoras. Dominar esta facultad resulta indispensable para el éxito personal y profesional.

    Te ofrecemos una Playlife Experience a partir de ejercicios de visualización creativa y ejercicios prácticos, donde los participantes aprenderán a utilizar su "memoria prospectiva" y entrenarán la generación de representaciones mentales enfocadas en metas de corto y largo plazo, lo que les permitirá conectar con sus aspiraciones profundas y modelar el futuro de acuerdo a sus propósitos personales.

    ¡Únete a nuestra comunidad de aprendizaje y aprende a cultivar tu memoria de futuro, toma un mayor control de tu vida y tu carrera, traza planes detallados para concretar tus sueños y construye tu propia agenda personal y profesional!
    `, img: 'url("../images/CursoG7.png")' },
    { title: 'EL ESTADO CRÍTICO DEL PENSAMIENTO: AFRONTAR LA ADVERSIDAD EN LA EMPRESA', text: `
    Si algo tenemos claro es que vivimos en un mundo de cambios vertiginosos donde la impredictibilidad del entorno genera múltiples desafíos en nuestros ámbitos personales y laborales. Más que nunca se requieren líderes capaces de pensar con claridad y tomar decisiones óptimas en medio de la adversidad, potenciando la capacidad crítica de procesar la complejidad y convertirla en oportunidades de crecimiento.

    El pensamiento crítico es una habilidad esencial para el desarrollo profesional y personal. Se trata de la capacidad de analizar, evaluar y generar argumentos, soluciones y decisiones de forma racional, lógica y creativa, considerando las evidencias, criterios y perspectivas disponibles. El pensamiento crítico permite afrontar problemas y desafíos, y a la vez desarrollar una actitud reflexiva, autónoma y responsable. En épocas disruptivas, un pensamiento ágil pero profundo resulta indispensable para liderar.

    Sin embargo, esta tarea no es sencilla, porque el pensamiento crítico se ve amenazado por diversos factores como la sobreinformación, la desinformación, la polarización, la manipulación, la presión social, el estrés, la incertidumbre y la complejidad de los problemas actuales. Todo esto dificulta su ejercicio y fomento, y puede generar confusión, frustración y conformismo, afectando negativamente el rendimiento y bienestar personal y profesional.

    Estos factores generan un estado mental en el que el pensamiento se bloquea o se desvía hacia formas irracionales, dogmáticas o conformistas.

    Este programa explorará cómo cultivar un estado mental óptimo para tomar decisiones acertadas y liderar bajo presión en tiempos desafiantes.

    Exploraremos el concepto de "sangre fría", que describe un estado de alerta enfocado, pensamiento estratégico y control emocional frente a amenazas. Investigaremos las bases neurológicas detrás de esta mente equilibrada que permite tomar mejores decisiones bajo presión extrema.

    Abordaremos hallazgos sobre la "neuroplasticidad" y cómo las experiencias adversas pueden fortalecer el carácter y generar resiliencia si adoptamos una mentalidad de crecimiento. Analizaremos estrategias para encontrar oportunidades de aprendizaje incluso en las crisis más desafiantes.

    También estudiaremos el poder de la visión compartida para mantener la cohesión, la comunicación efectiva para reducir la incertidumbre, y las redes de apoyo para disminuir el aislamiento, claves para navegar la adversidad colectivamente.

    En esta experiencia de vida los participantes entrenarán habilidades como pensamiento sistémico, análisis multifacético, razonamiento orientado a soluciones y deliberación focalizada, para maximizar su desempeño en situaciones exigentes. Con una metodología que incluye el juego como forma de aprendizaje y un marco teórico científico, los participantes desarrollarán habilidades que les permitan sintonizar con estados mentales propios y ajenos para comprender realidades subjetivas. Esto permite una visión multifacética de los problemas y mayor discernimiento al tomar decisiones.
    `, img: 'url("../images/CursoG8.png")' },
    { title: 'LIDERAZGO CREATIVO EN UN MUNDO V.U.C.A. ENCONTRANDO LA BELLEZA EN LO INESPERADO.', text: `
    ViCA significa Volatilidad, Incertidumbre, (uncertainty), Complejidad y Ambigüedad. Este término describe el escenario en que las empresas deben adaptarse continuamente a los cambios que afectan su planificación estratégica y rutinas.

    El entorno ViCA está presente en diversos sectores, donde los avances tecnológicos o fluctuaciones del mercado generan inestabilidad. Esta Playlife Experience crea una comunidad de aprendizaje enfocada en potenciar el pensamiento creativo para vislumbrar oportunidades e innovaciones, aplicando conocimientos de neurociencias.

    Nuestro cerebro tiene una asombrosa plasticidad para idear lo nuevo, especialmente ante lo inesperado. Esta experiencia explorará cómo liberar nuestro potencial creativo para liderar con originalidad en épocas de disrupción.

    Mediante un marco conceptual y ejercicios prácticos, los participantes entrenarán habilidades como pensamiento divergente, insight conectivo, futurología, agilidad mental y resolución creativa de problemas. El objetivo es convertir situaciones ambiguas en terreno fértil para la inventiva y acción estratégica.

    Se profundizará en conceptos como bisociación y pensamiento janusiano, que describen la capacidad de combinar ideas aparentemente inconexas para generar insights innovadores frente a desafíos complejos. Descubrirás técnicas para lograr este estado fluido.

    Nuestra Playlife Experience prepara para ejercer un liderazgo ágil e innovador, capaz de hallar soluciones donde otros ven caos e incertidumbre. Así se está listo para guiar equipos y organizaciones a navegar la complejidad de entornos VICA con éxito.

    Para sobrevivir en un futuro VICA hay que empezar a actuar ya. Buscamos participantes dispuestos a convertir situaciones ambiguas en terreno fértil para la inventiva y acción estratégica.

    A través de juegos que plantean escenarios, se desarrollan habilidades para 
    pre-experimentar múltiples futuros posibles como entrenamiento mental para la incertidumbre, capacidad propia de líderes creativos.

    `, img: 'url("../images/CursoG9.png")' },
  ]

  return (
    <div className={styles.main}>
      <div className={styles.wrap}>
        <img src={Logo} alt="Korumei Logo" className={styles.logo} />
        <img src={Squares} alt="squares" className={styles.squares} />
        <div className={styles.box}>
          <div className={styles.innerBox}>
            <div style={{ backgroundImage: `${coursesList[id].img}`}} />
          </div>
          <div className={styles.firstBox} />
          <div className={styles.outerBox} />
        </div>
        <div className={styles.textWrap}>
          <div className={styles.hWrap}>
            <h4>{coursesList[id].title}</h4>
            <img src={TitleDots} alt="tittledots" className={styles.dots} />
          </div>

          <p>{coursesList[id].text}</p>
        </div>
        <span className={styles.backButton} onClick={() => navigate('/')}>Volver</span>
        
      </div>
    </div>
  );
}
export default CourseScreen;