import { useState } from 'react';
import cls from 'classnames';
import styles from './Courses.module.scss'
import Squares from '../../images/svg/squares.svg';
import BigSquare from '../../images/svg/bigBlueSquare.svg';
import TitleDots from '../../images/svg/TitleDots.svg';
import { useNavigate } from 'react-router-dom'

function Courses() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [activeCourse, setActiveCourse] = useState(0)

  const coursesList = [[
    { id: 0, title: 'Un workplace sin conflictos, creando un entorno productivo y armonioso.', img: 'url("./images/CursoG1.png")' },
    { id: 1, title: 'Cómo dar buenas noticias a equipos difíciles.', img: 'url("./images/CursoG2.png")' },
    { id: 2, title: 'Being Tough, recuperarse de los fallos.', img: 'url("./images/CursoG3.png")' },
  ], [
    { id: 3, title: 'Momentos decisivos: La Neurociencia en la toma de decisiones.', img: 'url("./images/CursoG4.png")' },
    { id: 4, title: 'La comunicación sólida. El poder del silencio en el mundo digital.', img: 'url("./images/CursoG5.png")' },
    { id: 5, title: 'Patrones mentales del pensamiento optimista.', img: 'url("./images/CursoG6.png")' },
  ], [
    { id: 6, title: 'Desarrollar la memoria de futuro. Tomar el control de tu agenda. ', img: 'url("./images/CursoG7.png")' },
    { id: 7, title: 'El estado crítico del pensamiento. Afrontar la adversidad en la empresa.', img: 'url("./images/CursoG8.png")' },
    { id: 8, title: ' Liderazgo creativo en un mundo V.I.C.A. Encontrando la belleza en lo inesperado.', img: 'url("./images/CursoG9.png")' },
  ]
]
  return (
    <div className={styles.main} id='courses'>

      <div className={styles.wrap}>
        <div className={styles.hWrap}>
          <h1>CURSOS <br /> KORUMEI</h1>
          <img src={TitleDots} alt="tittledots" className={styles.dots} />
        </div>  
      
        <img src={Squares} alt="squares" className={styles.squares} />
        <img src={BigSquare} alt="squares" className={styles.leftSquare} />
        <img src={BigSquare} alt="squares" className={styles.rightSquare} />
        
        <div className={styles.coursesWrap}>

          { coursesList[activeTab].map((e, idx) => {
            return <div className={styles.curso} key={idx}>
              <div className={styles.box}>
                <div className={styles.innerBox}>
                  <div style={{ backgroundImage: `${e.img}`}} />
                </div>
                <div className={styles.firstBox} />
                <div className={styles.outerBox} />
              </div>
              <h3 onClick={() => navigate(`/curso/${e.id}`)}>{e.title}</h3>
            </div>

          })}
        </div>  

        <div className={styles.coursesWrapMobile}>
          
            <div className={styles.curso}>
              <div className={styles.box}>
                <div className={styles.innerBox}>
                  <div style={{ backgroundImage: `${coursesList[activeTab][activeCourse].img}`}} />
                </div>
                <div className={styles.firstBox} />
                <div className={styles.outerBox} />
              </div>
              <h3 onClick={() => navigate(`/curso/${coursesList[activeTab][activeCourse].id}`)}>{coursesList[activeTab][activeCourse].title}</h3>
            </div>

            <div className={styles.carousel}>
              {coursesList[activeTab].map((e, idx) => {
                return <div 
                  key={idx} 
                  onClick={() => setActiveCourse(idx)} 
                  className={cls({[styles.carouselDotActive]: activeCourse === idx} , styles.carouselDot)}
                />
              })}
            </div>
        </div>

        <div className={styles.selectorWrap}>
          <div className={cls({[styles.active]: activeTab === 0}, styles.selectorBlue)} onClick={() => setActiveTab(0)}/>
          <div className={cls({[styles.active]: activeTab === 1}, styles.selectorYellow)} onClick={() => setActiveTab(1)}/>
          <div className={cls({[styles.active]: activeTab === 2}, styles.selectorRed)} onClick={() => setActiveTab(2)}/>
        </div>
       
      </div>
    </div>
  );
}
export default Courses;