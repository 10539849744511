import React, { useEffect, useState } from 'react';
import styles from './Header.module.scss';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Logo from '../../images/logo.png';
import LogoBlue from '../../images/logoBlue.png';
import Menu from '../../images/svg/menu.svg';
import cls from 'classnames';
import square from '../../images/svg/menuSquare.svg';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false)
  const settings = ['Productos y  servicios', 'Sobre Nosotros', 'Cursos Korumei', 'Misión y Visión', 'Nuestro Equipo'];

  return (
    <AppBar position="static" className={styles.main} sx={{ backgroundColor: { md:'#002853', xs: '#ffffff'}, boxShadow: { md: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)', xs: 'none' } }}>
      <Container className={styles.container}>
        <Toolbar disableGutters sx={{ minHeight: 85, height: 85 }}>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none'} }}>
            <img src={LogoBlue} alt="Korumei Logo" className={styles.logoMobile} />
          </Box> 

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' },  alignItems: 'center'  }}>
            <img src={Logo} alt="Korumei Logo" className={styles.logoMobile} />
            <a href="#products" sx={{ my: 2, color: 'white', display: 'block'}}>Productos Y <br></br> servicios</a>
            <a href="#aboutUs" sx={{ my: 2, color: 'white', display: 'block' }}>SOBRE <br></br> Nosotros</a>
            <a href="#courses" sx={{ my: 2, color: 'white', display: 'block' }}>Cursos <br></br> Korumei</a>
            <a href="#mision" sx={{ my: 2, color: 'white', display: 'block' }}>Misión y <br></br> Visión</a>
            <a href="#ourTeam" sx={{ my: 2, color: 'white', display: 'block' }}>Nuestro <br></br> Equipo</a>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },  alignItems: 'center'  }}>
            <div onClick={() => {setMenuOpen(!menuOpen)}} className={styles.menuButton}><img src={Menu} alt="menu icon" /></div>
            <div className={cls({[styles.open]: menuOpen}, styles.menu)} onClick={() => {setMenuOpen(!menuOpen)}}>
              <img src={square} className={styles.square} />
              <div className={styles.menuButtonOpen}><img src={Menu} alt="menu icon" /></div>
              <a href="#products" sx={{ my: 2, color: 'white', display: 'block' }}>Productos Y<br></br> servicio</a>
              <div className={styles.separator}/>
              <a href="#aboutUs" sx={{ my: 2, color: 'white', display: 'block' }}>SOBRE<br></br> Nosotros</a>
              <div className={styles.separator}/>
              <a href="#courses" sx={{ my: 2, color: 'white', display: 'block' }}>Cursos<br></br> Korumei</a>
              <div className={styles.separator}/>
              <a href="#mision" sx={{ my: 2, color: 'white', display: 'block' }}>Misión y<br></br> Visión</a>
              <div className={styles.separator}/>
              <a href="#ourTeam" sx={{ my: 2, color: 'white', display: 'block' }}>Nuestro<br></br> Equipo</a>
              <div className={styles.separator}/>
            </div>

          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;