import styles from './OurTeam.module.scss'
import TitleDots from '../../images/svg/TitleDots.svg';
import TopWaves from '../../images/svg/topWaves.svg';
import BottomWaves from '../../images/svg/bottomWaves.svg';

function OurTeam() {
  return (
    <div className={styles.main} id="aboutUs">
      <img src={TopWaves} alt="waves" className={styles.topWaves}/>

      <div className={styles.wrap}>
        <div className={styles.textWrap}>
          <div className={styles.hWrap}>
            <h1>SOBRE <br /> NOSOTROS</h1>
            <img src={TitleDots} alt="tittledots" className={styles.dots} />
          </div>
          <h3>Nuestro equipo diverso se compone de educadores, investigadores, empresarios y tecnólogos, programadores e ingenieros de datos, todos unidos por una pasión compartida por el desarrollo personal y profesional. Juntos, estamos comprometidos a brindar una experiencia de aprendizaje incomparable para profesionales que destaquen en un mundo desafiante en constante evolución.</h3>
          <p>En una era en la que los límites del potencial humano se expanden continuamente, el dominio de la neurociencia se ha convertido en un activo indispensable para los profesionales que dan forma a nuestro futuro colectivo.</p>
          <p>En KORUMEI estamos a la vanguardia de este cambio transformador, ayudando a las personas y organizaciones para que alcancen todo su potencial a través de una amplia gama de experiencias formativas hechas a medida, adaptadas a las necesidades únicas de cada organización.</p>
          <p>La experiencia de vida es algo más que una simple acumulación de hechos y datos. Es una forma de aprender de nuestros errores y aciertos, de reflexionar sobre lo que nos pasa y lo que nos hace sentir, de crecer como personas y como seres humanos. La experiencia de vida nos ayuda a entender mejor el mundo y a nosotros mismos, a desarrollar nuestra sabiduría y nuestra compasión, a encontrar nuestro propósito y nuestra felicidad.</p>
          <p>Tomamos como símbolo al Koru porque nos recuerda que la renovación es una parte natural de la vida y que, como empresa, debemos estar dispuestos a adaptarnos y cambiar para seguir creciendo. Al igual que el Koru debemos aprender constantemente y crear nuevas oportunidades para la creatividad y la innovación.</p>
        </div>  

        <div className={styles.box}>
          <div className={styles.innerBox}>
            <div style={{ backgroundImage: 'url("./images/AboutUs.png")'}} />
          </div>
          <div className={styles.firstBox} />
          <div className={styles.outerBox} />
        </div>
      </div>  
      <img src={BottomWaves} alt="waves" className={styles.bottomWaves}/>
    </div>
  );
}
export default OurTeam;