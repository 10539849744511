import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import styles from './Screens.module.scss'
import Footer from '../components/footer/Footer';
import LandingScreen from './landingscreen/LandingScreen';
import CourseScreen from './courseScreen/CourseScreen';

function Screens() {
  return (
    <div>
       <Router>
        <Routes>
         <Route path="/" element={<LandingScreen />}/>
         <Route path="/curso/:id" element={<CourseScreen />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default Screens;
