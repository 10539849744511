import styles from './Products.module.scss';
import TopWaves from '../../images/svg/topWaves.svg';
import BottomWaves from '../../images/svg/bottomWaves.svg';
import TitleDots from '../../images/svg/TitleDots.svg';
import HatIcon from '../../images/svg/hatIcon.svg';
import Dot from '../../images/svg/littleDot.svg';

function Products() {
  return (
    <div className={styles.main} id="products">

      <img src={TopWaves} alt="waves" className={styles.topWaves}/>
      <div className={styles.wrap}>
        <div className={styles.textWrap}>
          <div className={styles.hWrap}>
            <h1>PRODUCTOS Y <br/> SERVICIOS</h1>
            <img src={TitleDots} alt="tittledots" className={styles.dots} />
          </div>
          <p>La vida nos enseña, la experiencia nos moldea y el juego nos hace felices. Playlife Experience es una propuesta innovadora de experiencias de vida que unen momentos de divertida relajación, de desarrollo de skills y de adquisición de la cultura empresarial.</p>
          <p>Korumei lleva a sus participantes a incursionar en verdaderas Playlife Experiences, conformada a la medida de cada uno y haciendo de ellas algo único, original e irrepetible, donde jugar dentro de una comunidad de aprendizaje será siempre el punto de partida.</p>
          <p>Ponte una meta, personal u organizacional, elige dentro de nuestra amplia oferta de experiencias únicas y conviértela en una realidad a través de PlayLife Experience. </p>
          <p>Deja que Playlife Experience te atrape, busca algo nuevo cada día, adquiere un pasaporte para tu futuro, asume el poder, invierte en ti mismo, crece profesionalmente y sé siempre más competitivo y exitoso junto al equipo de trabajo de Playlife Experience y sé parte de una real comunidad de aprendizaje.</p>
          <p>¡Únete a Playlife Experience y vive la experiencia de tu vida!</p>
        </div>

        <div className={styles.box}>
          <div className={styles.innerBox}>
            <div style={{ backgroundImage: 'url("./images/Products.jpg")'}} />
          </div>
          <div className={styles.firstBox} />
          <div className={styles.outerBox} />
        </div>

      </div>
      <img src={BottomWaves} alt="waves" className={styles.bottomWaves}/>

    </div>
  );
}
export default Products;