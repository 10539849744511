import { useState } from 'react';
import styles from './Mision.module.scss';
import TitleDots from '../../images/svg/TitleDots.svg';
import Dot from '../../images/svg/littleDot.svg';
import cls from 'classnames';

function Mision() {
  const [activeMember, setActiveMember] = useState(0)
  const memberList = [
    { name: 'Beatriz Pizarro Reig', position: 'CEO - Founder', img: 'url("./images/Beatriz.png")',  list: [
      'Licenciada en Psicología y Ciencias de la Educación.',
      'Profesora en Psicología y Ciencias de la Educación.',
      'Fundadora e Investigadora en Neuronotes Lab y Consultora de otras instituciones educativas.',
      'Autora de los libros: “Neurociencia y Educación” (2022) “Con sed de aprender”(2000)  “La inteligencia y el niño diferente”(2004)  “Estilos de aprendizaje, verdad o mito” (1998).'
    ]}, { name: 'Beatriz Silva', position: 'Academic Director', img: 'url("./images/Silva.png")',  list: [
      'Licenciada en Letras. Facultad de Filosofía y Letras, UNCuyo.',
      'Posgrado en Pedagogía, Universidad de Congreso y Playa Ancha, Chile.',
      'Experta en Neurociencias y Neuroeducación, Neuronote Lab Berlín.',
      'Autora de: “Las capacidades y su planificación”. “Mendoza: ciencias sociales”. “Cómo evaluar capacidades”. “Si de lectura y escritura se trata...” “El liderazgo interior”'
    ]}, { name: 'Yesica Ibach', position: 'Director of Institutional Relations', img: 'url("./images/Yesica.png")',  list: [
      'Profesora, licenciada y especialista en lengua, literatura, educación, tecnología y TIC.',
      'Experta en Neurociencias y Neuroeducación, Neuronote Lab Berlín.',
      'Capacitadora en proyectos de gestión, reclutadora de personal docente, asesora académica y curriculista.',
      'Experta en convenios académicos nacionales e internacionales.'
    ]}, { name: 'Jorge Ricardo Serrano', position: 'CTO', img: 'url("./images/Jorge.png")',  list: [
      'Ingeniero en Electrónica y Electricidad.',
      'Máster en Telecomunicaciones.',
      'Experto en Data Science y Matemáticas.',
      'Analista de Maching Learnig.',
      'Analista en Automatizaciones y TIC.',
      'Auditor de comunicaciones electricidad.',
      'Diseñador de redes de transmisión de datos por la Universidad Austral.'
    ]}, { name: 'Ayelen Zulliger', position: 'CFO - Head of Legal', img: 'url("./images/Ayelen.png")',  list: [
      'Abogada con más de 11 años de experiencia legal y financiera en empresas europeas.',
      'Máster en finanzas.',
      'CFO en startups alemanas y españolas.',
      'Especialista en procesos financieros europeos.',
      'Ha obtenido múltiples avales y fondos europeos como ENISA, CDTI, ICO etc...',
      'Ha liderado equipos legales y financieros en Alemania y España.'
    ]}, { name: 'Juan Diego Zulliger', position: 'Head of HR', img: 'url("./images/Juan.png")',  list: [
      'Graduado en Economía.',
      'Más de 7 años de experiencia en Tech Recruitment.',
      'Ha liderado equipos distribuidos de hasta 5 personas en empresas alemanas y de LATAM.',
      'Entre sus clientes han estado Originate, Bertelsman, Swarovski Group, Memsource GmbH, EverReal GmbH,  Soda IO, y otros.'
    ]},
  ]

  return (
    <div className={styles.main} id="mision">
      <div className={styles.wrap}>
        <div className={styles.hWrap}>
          <h1>VISIÓN, <br /> Y MISIÓN</h1>
          <img src={TitleDots} alt="tittledots" className={styles.dots} />
        </div>  
        <div className={styles.misionWrap}>
          <div className={styles.containerBoxGreen}>
            <h3>VISIÓN</h3>
            <p>Despertar a las mentes. Que se escuche en cada casa, en cada empresa, en cada escuela el grito de ¡Eureka!</p>
            <div className={styles.greenSquare}/>
          </div>
          <div className={styles.containerBoxGray}>
            <h3>MISIÓN</h3>
            <p>Buscamos llevar nuestras Playlife Experience a las más recónditas regiones del planeta, impulsados por la pasión de la educación y el deseo de ayudar a alcanzar el máximo potencial personal y profesional, en cualquier ámbito laboral y  trabajar junto a ellos en favor del progreso individual y organizacional. Nos enfocamos en la formación de líderes y ciudadanos responsables y comprometidos con el cambio social y el bienestar de la comunidad.Desarrollamos experiencias educativas innovadoras y dinámicas, basadas en el compromiso con la investigación y el desarrollo de nuevas herramientas científicas tecnológicas para crear verdaderas comunidades de aprendizaje.</p>
            <div className={styles.blueSquare}/>
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.innerBox}>
            <div style={{ backgroundImage: 'url("./images/Vision.png")'}} />
          </div>
          <div className={styles.firstBox} />
          <div className={styles.outerBox} />
        </div>

        <div className={styles.hWrap} id="ourTeam">
          <h1>NUESTRO <br /> EQUIPO DIRECTIVO</h1>
          <img src={TitleDots} alt="tittledots" className={styles.dots} />
        </div>  

        <div className={styles.teamWrapDesktop}>
          {
            memberList.map((e, idx) => {
              return <div className={styles.memberWrap} key={idx}>
              <div className={styles.memberImgWrap}>
                <div className={styles.memberImg} style={{ backgroundImage: `${e.img}`}} />
              </div>
              <div className={styles.memberText}>
                <h4>{e.name}</h4>
                <h5>{e.position}</h5>
                <div className={styles.listWrap}>
                { e.list.map((item, id) => {
                  return <h6 key={id}><img src={Dot} alt="dot" className={styles.dot} />{item}</h6>
                })}
                </div>
              </div>
            </div>
            })
          }
        </div>
        
        <div className={styles.teamWrapMobile}>
          <div className={styles.memberWrap}>
            <div className={styles.memberImgWrap}>
              <div className={styles.memberImg} style={{ backgroundImage: `${memberList[activeMember].img}`}} />
            </div>
            <div className={styles.memberText}>
              <h4>{memberList[activeMember].name}</h4>
              <h5>{memberList[activeMember].position}</h5>
              <div className={styles.listWrap}>
              { memberList[activeMember].list.map((item, id) => {
                return <h6 key={id}><img src={Dot} alt="dot" className={styles.dot} />{item}</h6>
              })}
              </div>
            </div>
          </div>

          <div className={styles.carousel}>
            {memberList.map((e, idx) => {
              return <div 
                key={idx} 
                onClick={() => setActiveMember(idx)} 
                className={cls({[styles.carouselDotActive]: activeMember === idx} , styles.carouselDot)}
              />
            })}
          </div>
        </div>


      </div>
    </div>
  );
}
export default Mision;