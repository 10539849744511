import { useEffect } from 'react'
import styles from './LandingScreen.module.scss';
import Courses from '../../components/courses/Courses';
import Landing from '../../components/landing/Landing';
import Mision from '../../components/mision/Mision';
import OurTeam from '../../components/ourteam/OurTeam';
import Products from '../../components/products/Products';
import Header from '../../components/header/Header';

function LandingScreen() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.main}>
      <Header />
      <Landing />
      <Products />
      <Courses />
      <OurTeam />
      <Mision />
    </div>
  );
}
export default LandingScreen;